<template>
  <div class="app-container">
    <div class="full">
      <el-form :inline="true">
        <el-form-item label="会议地址"
          ><el-input v-model="serachaddress"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList">搜索</el-button>
          <el-button type="primary" @click="add">新增</el-button>
        </el-form-item>
      </el-form>

      <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;"
        border
        stripe
        default-expand-all
      >
        <el-table-column prop="city" label="区域"> </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
        <el-table-column prop="trainStartTime" label="会议开始时间">
        </el-table-column>
        <el-table-column prop="trainEndTime" label="会议结束时间">
        </el-table-column>
        <el-table-column prop="icon" label="操作">
          <template slot-scope="scope">
            <el-button @click="getqrCode(scope.row)" type="text">
              获取二维码</el-button
            >
            <el-button @click="updateqrCode(scope.row)" type="text">
              修改</el-button
            >
            <!-- <el-button @click="downqrCode(scope.row)" type="text">
              下载二维码</el-button
            > -->
            <a
              :href="
                'http://signin.sccqhome.cn/api/file/download?fileUrl=' +
                  scope.row.qrCode
              "
              type="download"
            >
              下载二维码</a
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="sizechange"
        @current-change="pagechange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 50, 100]"
        :current-page="page.index"
        :page-size="page.size"
        :total="page.total"
      >
      </el-pagination>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="handleClose"
      >
        <el-form
          :model="editForm"
          ref="editForm"
          label-width="100px"
          class="demo-editForm"
        >
          <el-form-item label="所属市州" prop="name" label-width="100px">
            <el-select style="width:100%" v-model="editForm.city">
              <el-option
                v-for="(item, index) in cityList"
                :key="'item_' + index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="会场地址" prop="icon" label-width="100px">
            <el-input v-model="editForm.address"></el-input>
          </el-form-item>
          <el-form-item label="经度" prop="path" label-width="100px">
            <el-input v-model="editForm.sign_longitude"></el-input>
          </el-form-item>
          <el-form-item label="维度" prop="component" label-width="100px">
            <el-input v-model="editForm.sign_latitude"></el-input>
          </el-form-item>
          <el-form-item
            label="上午开始时间"
            prop="component"
            label-width="100px"
          >
            <el-date-picker
              v-model="editForm.trainStartTime"
              style="width:100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="上午结束时间"
            prop="component"
            label-width="100px"
          >
            <el-date-picker
              v-model="editForm.noonTime"
              style="width:100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            label="下午开始时间"
            prop="component"
            label-width="100px"
          >
            <el-date-picker
              v-model="editForm.afterTime"
              style="width:100%"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item
            label="下午结束时间"
            prop="component"
            label-width="100px"
          >
            <el-date-picker
              v-model="editForm.trainEndTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width:100%"
              type="datetime"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('editForm')"
              >立即创建</el-button
            >
            <el-button @click="resetForm('editForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <el-dialog
        title="二维码"
        :visible.sync="dialogQrCode"
        width="600px"
        :before-close="CloseQrcode"
      >
        <div class="imgBox">
          <img
            :src="'http://signin.sccqhome.cn/api//file/show/' + qrcode"
            alt=""
          />
<!--          <img-->
<!--            :src="'http://192.168.2.53:8081/file/show/' + qrcode"-->
<!--            alt=""-->
<!--          />-->
        </div>
      </el-dialog>

      <!-- <el-dialog
        title="签到记录"
        :visible.sync="signVisible"
        width="600px"
        :before-close="handleClose"
      >
        <el-table :data="signList" v-if="signVisible">
          <el-table-column prop="signTime" label="签到时间"> </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="signVisible = false"
            >确定</el-button
          >
        </div>
      </el-dialog> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      editForm: {
        city: "",
        address: "",
        sign_longitude: "",
        sign_latitude: "",
        trainStartTime: "",
        trainEndTime: "",
      },
      cityList: [
        {
          name: "成都市",
        },
        {
          name: "南充市",
        },
        {
          name: "广元市",
        },
        {
          name: "巴中市",
        },
        {
          name: "广安市",
        },
        {
          name: "眉山市",
        },
        {
          name: "遂宁市",
        },
        {
          name: "达州市",
        },

        {
          name: "绵阳市",
        },
        {
          name: "资阳市",
        },

        {
          name: "德阳市",
        },
        {
          name: "内江市",
        },
        {
          name: "乐山市",
        },
        {
          name: "宜宾市",
        },
        {
          name: "泸州市",
        },
        {
          name: "凉山彝族自治州",
        },

        {
          name: "自贡市",
        },
        {
          name: "攀枝花市",
        },
        {
          name: "甘孜藏族自治州",
        },
        {
          name: "阿坝藏族羌族自治州",
        },
        {
          name: "雅安市",
        },
      ],
      page: {
        index: 1,
        size: 10,
        total: 0,
      },
      serachaddress: "",
      dialogQrCode: false,
      qrcode: "",
      id: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    resetForm() {
      this.editForm = {
        city: "",
        address: "",
        sign_longitude: "",
        sign_latitude: "",
        trainStartTime: "",
        trainEndTime: "",
      };
    },
    sizechange(val) {
      this.page.size = val;
      this.getList();
    },
    pagechange(val) {
      this.page.index = val;
      this.getList();
    },
    CloseQrcode() {
      this.dialogQrCode = false;
    },
    /**
     * 下载二维码
     */
    downqrCode(row) {
      let params = {
        fileUrl: row.qrCode,
      };
      console.log(params, 666666);
      this.$axios.get("/file/download", { params: params }).then((res) => {
        // this.$message({
        //   showClose: true,
        //   message: "恭喜你，操作成功",
        //   type: "success",
        //   onClose: () => {
        //     this.getMenuTree();
        //   },
      });
    },
    getqrCode(row) {
      this.qrcode = row.qrCode;
      this.dialogQrCode = true;
    },
    updateqrCode(row) {
      this.id = row.id;
      this.getInfo();
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    add() {
      this.id = "";
      this.dialogVisible = true;
      this.editForm = {
        city: "",
        address: "",
        sign_longitude: "",
        sign_latitude: "",
        trainStartTime: "",
        trainEndTime: "",
      };
    },

    getInfo() {
      this.$axios.get(`sys/dtn/findById/${this.id}`).then((res) => {
        this.editForm = res.data.data;
        this.editForm.sign_latitude = res.data.data.latitude;
        this.editForm.sign_longitude = res.data.data.longitude;
        this.editForm = JSON.parse(JSON.stringify(this.editForm));
      });
    },
    /**
     * 提交表单
     */
    submitForm() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          if (this.id == "") {
            this.$axios
              .post("sys/sign/findByQrCode", this.editForm)
              .then((res) => {
                this.$message({
                  showClose: true,
                  message: "恭喜你，操作成功",
                  type: "success",
                  onClose: () => {
                    this.getMenuTree();
                  },
                });
                this.getList();
                this.dialogVisible = false;
              });
          } else {
            this.editForm.id = this.id;
            this.editForm.latitude = this.editForm.sign_latitude;
            this.editForm.longitude = this.editForm.sign_longitude;
            this.$axios.post("sys/dtn/update", this.editForm).then((res) => {
              this.$message({
                showClose: true,
                message: "恭喜你，操作成功",
                type: "success",
                onClose: () => {
                  this.getMenuTree();
                },
              });
              this.getList();
              this.dialogVisible = false;
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 会议历史列表
     */
    getList() {
      this.$axios
        .post("/sys/dtn/findList", {
          current: this.page.index,
          size: this.page.size,
          address: this.serachaddress,
        })
        .then((res) => {
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        });
    },
  },
  created() {},
  watch: {},
};
</script>

<style>
.imgBox {
  width: 450px;
  margin: 0 auto;
  text-align: center;
}
.imgBox img {
  width: 100%;
}
</style>
