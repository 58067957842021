import axios from "axios";
import router from "./router";
import Element from "element-ui";

// axios.defaults.baseURL = "http://192.168.2.36:8189";
axios.defaults.baseURL = "http://sign.sccqhome.cn/api";

const request = axios.create({
  timeout: 5000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

request.interceptors.request.use((config) => {
  // debugger
  config.headers["Authorization"] = sessionStorage.getItem("token"); //解决token失效的问题
  // config.headers['Authorization'] = localStorage.getItem("token")
  return config;
});

request.interceptors.response.use(
  (response) => {
    // console.log(JSON.stringify(response), 969696);
    // console.log("response ->" + response);
    // console.log(response, 969696);
    // let res = response.data;

    if (response.status === 200) {
      return response;
    } else {
      Element.Message.error(!res.msg ? "系统异常" : res.msg);
      return Promise.reject(response.data.msg);
    }
  },
  (error) => {
    console.log(error);

    if (error.response.data) {
      error.massage = error.response.data.msg;
    }

    if (error.response.status === 401) {
      router.push("/login");
    }

    Element.Message.error(error.massage, { duration: 3000 });
    return Promise.reject(error);
  }
);

export default request;
