<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input v-model="searchForm.username" placeholder="用户名" clearable>
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="getUserList">搜索</el-button>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          @click="dialogVisible = true"
          v-if="hasAuth('sys:user:save')"
          >新增</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
          <el-button
            type="danger"
            slot="reference"
            :disabled="delBtlStatu"
            v-if="hasAuth('sys:user:delete')"
            >批量删除</el-button
          >
        </el-popconfirm>
      </el-form-item>
    </el-form>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>

      <el-table-column label="头像" width="50">
        <template slot-scope="scope">
          <el-avatar size="small" :src="scope.row.avatar"></el-avatar>
        </template>
      </el-table-column>

      <el-table-column prop="username" label="用户名" width="120">
      </el-table-column>
      <el-table-column prop="roleName" label="角色名称">
        <!-- <template slot-scope="scope">
          <el-tag
            size="small"
            type="info"
            v-for="item in scope.row.sysRoles"
            :key="item"
            >{{ item.name }}</el-tag
          >
        </template> -->
      </el-table-column>
      <el-table-column prop="email" label="邮箱"> </el-table-column>
      <el-table-column prop="phoneNum" label="手机号"> </el-table-column>
      <el-table-column prop="statu" label="状态">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.status === 1" type="success"
            >正常</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.status === 0" type="danger"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="created" width="200" label="创建时间">
      </el-table-column>
      <el-table-column prop="icon" width="260px" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="roleHandle(scope.row.id)"
            >分配角色</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <el-button
            type="text"
            @click="repassHandle(scope.row.id, scope.row.username)"
            >重置密码</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <el-button type="text" @click="editHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm
              title="这是一段内容确定删除吗？"
              @confirm="delHandle(scope.row.id)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page="current"
      :page-size="size"
      :total="total"
    >
    </el-pagination>

    <!--新增对话框-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-form :model="editForm" :rules="editFormRules" ref="editForm">
        <el-form-item label="区域" prop="city" label-width="100px">
          <el-select style="width:100%" v-model="editForm.city">
            <el-option
              v-for="(item, index) in cityList"
              :key="'item_' + index"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名" prop="nickname" label-width="100px">
          <el-input
            v-model="editForm.nickname"
            autocomplete="off"
            maxlength="12"
          ></el-input>
          <el-alert
            title="初始密码为888888"
            :closable="false"
            type="info"
            style="line-height: 12px;"
          ></el-alert>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" label-width="100px">
          <el-input v-model="editForm.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="username" label-width="100px">
          <el-input v-model="editForm.username" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="status" label-width="100px">
          <el-radio-group v-model="editForm.status">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 分配权限对话框 -->
    <el-dialog
      title="分配角色"
      :visible.sync="roleDialogFormVisible"
      width="600px"
    >
      <el-form :model="roleForm">
        <el-tree
          :data="roleTreeData"
          show-checkbox
          ref="roleTree"
          :check-strictly="checkStrictly"
          node-key="id"
          :default-expand-all="true"
          :props="defaultProps"
        >
        </el-tree>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRoleHandle('roleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Role",
  data() {
    let validateEmail = (rule, value, cb) => {
      const mal = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (rule.required && !value) {
        return cb(new Error("邮箱不能为空"));
      }
      if (value) {
        if (!mal.test(value)) {
          cb(new Error("请输入正确的邮箱格式"));
        } else {
          cb();
        }
      }
    };

    let validatePhone = (rule, value, cb) => {
      // const mal = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      // if (rule.required && !value) {
      //   return cb(new Error("邮箱不能为空"));
      // }
      if (value) {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (!reg.test(value)) {
          cb(new Error("请输入正确的手机格式"));
        } else {
          cb();
        }
      } else {
        cb();
      }
    };
    return {
      searchForm: {},
      delBtlStatu: true,
      cityList: [
        {
          name: "成都市",
        },
        {
          name: "南充市",
        },
        {
          name: "广元市",
        },
        {
          name: "巴中市",
        },
        {
          name: "广安市",
        },
        {
          name: "眉山市",
        },
        {
          name: "遂宁市",
        },
        {
          name: "达州市",
        },

        {
          name: "绵阳市",
        },
        {
          name: "资阳市",
        },

        {
          name: "德阳市",
        },
        {
          name: "内江市",
        },
        {
          name: "乐山市",
        },
        {
          name: "宜宾市",
        },
        {
          name: "泸州市",
        },
        {
          name: "凉山彝族自治州",
        },

        {
          name: "自贡市",
        },
        {
          name: "攀枝花市",
        },
        {
          name: "甘孜藏族自治州",
        },
        {
          name: "阿坝藏族羌族自治州",
        },
        {
          name: "雅安市",
        },
      ],
      total: 0,
      size: 10,
      current: 1,

      dialogVisible: false,
      editForm: {},

      tableData: [],

      editFormRules: {
        city: [{ required: true, message: "请选择区域", trigger: "change" }],
        nickname: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            validator: validateEmail,
            trigger: "blur",
          },
        ],
        username: [
          {
            validator: validatePhone,
            trigger: "blur",
          },
        ],

        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },

      multipleSelection: [],

      roleDialogFormVisible: false,
      defaultProps: {
        children: "children",
        label: "name",
      },
      roleForm: {},
      roleTreeData: [],
      treeCheckedKeys: [],
      checkStrictly: true,
    };
  },
  created() {
    this.getUserList();

    this.$axios.get("/sys/role/list").then((res) => {
      this.roleTreeData = res.data.data.records;
    });
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      console.log("勾选");
      console.log(val);
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0;
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.getUserList();
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
    },

    getUserList() {
      this.$axios
        .post("/sys/user/findList", {
          current: this.current,
          size: this.size,
          username: this.searchForm.username,
          types: "2",
        })
        .then((res) => {
          this.tableData = res.data.data.records;
          this.size = res.data.data.size;
          this.current = res.data.data.current;
          this.total = res.data.data.total;
        });
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios
            .post(
              "/sys/user/" + (this.editForm.id ? "update" : "save"),
              this.editForm
            )
            .then((res) => {
              this.$message({
                showClose: true,
                message: "恭喜你，操作成功",
                type: "success",
                onClose: () => {
                  this.getUserList();
                },
              });

              this.dialogVisible = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get("/sys/user/info/" + id).then((res) => {
        this.editForm = res.data.data;

        this.dialogVisible = true;
      });
    },
    delHandle(id) {
      var ids = [];

      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach((row) => {
          ids.push(row.id);
        });
      }

      console.log(ids);

      this.$axios.post("/sys/user/delete", ids).then((res) => {
        this.$message({
          showClose: true,
          message: "恭喜你，操作成功",
          type: "success",
          onClose: () => {
            this.getUserList();
          },
        });
      });
    },

    roleHandle(id) {
      this.roleDialogFormVisible = true;

      this.$axios.get("/sys/user/info/" + id).then((res) => {
        this.roleForm = res.data.data;

        let roleIds = [];
        res.data.data.sysRoles.forEach((row) => {
          roleIds.push(row.id);
        });

        this.$refs.roleTree.setCheckedKeys(roleIds);
      });
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys();

      console.log(roleIds);

      this.$axios
        .post("/sys/user/role/" + this.roleForm.id, roleIds)
        .then((res) => {
          this.$message({
            showClose: true,
            message: "恭喜你，操作成功",
            type: "success",
            onClose: () => {
              this.getUserList();
            },
          });

          this.roleDialogFormVisible = false;
        });
    },
    repassHandle(id, username) {
      this.$confirm("将重置用户【" + username + "】的密码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios.post("/sys/user/repass", id).then((res) => {
          this.$message({
            showClose: true,
            message: "恭喜你，操作成功",
            type: "success",
            onClose: () => {},
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.el-pagination {
  float: right;
  margin-top: 22px;
}
</style>
